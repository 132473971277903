import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Register GSAP plugin
gsap.registerPlugin(ScrollTrigger);

// Define the type for FAQ items
interface FAQItem {
  question: string;
  answer: string;
  clientImage?: string; // Add optional client image
  assistantImage?: string; // Add optional assistant image
}
const faqs: FAQItem[] = [
  {
    question: "Care sunt factorii care influențează prețul serviciilor voastre? 💰",
    answer: "Prețul variază în funcție de complexitatea proiectului, tehnologiile folosite și termenul de livrare. Suntem aici să te ajutăm să găsești cea mai bună soluție pentru nevoile tale! 📈",
    clientImage: "imagini/client-avatar.jpg",
    assistantImage: "imagini/itvision-avatar.png",
  },
  {
    question: "Înțeleg. Și ce tehnologii preferați pentru dezvoltarea aplicațiilor? ⚙️",
    answer: "Folosim tehnologii moderne precum React și Node.js. Acestea ne permit să livrăm soluții eficiente și scalabile, adaptate nevoilor clienților noștri. 🚀",
    clientImage: "imagini/client-avatar.jpg",
    assistantImage: "imagini/itvision-avatar.png",
  },
  {
    question: "Ce măsuri luați pentru a garanta securitatea aplicațiilor dezvoltate? 🔒",
    answer: "Adoptăm cele mai bune practici de securitate, inclusiv criptarea datelor, teste împotriva atacurilor de tip 'brute force' și actualizări regulate, pentru a proteja aplicațiile tale. 🔐",
    clientImage: "imagini/client-avatar.jpg",
    assistantImage: "imagini/itvision-avatar.png",
  },
  {
    question: "Perfect! Și ce tipuri de suport tehnic oferiți după lansarea proiectului? 📞",
    answer: "Oferim suport tehnic continuu, actualizări de software și asistență pentru utilizatori. Ne dorim ca proiectul tău să fie mereu funcțional și actualizat, fără griji! 🔧",
    clientImage: "imagini/client-avatar.jpg",
    assistantImage: "imagini/itvision-avatar.png",
  },
  
    {
      question: "Hmm.. Ok 🤝. Cum pot să vă contactez? ",
      answer: "Ne poți suna la 0770674079 sau trimite un email la office@itvisionsoftware.ro. Așteptăm mesajul tău🤝",
      clientImage: "imagini/client-avatar.jpg",
      assistantImage: "imagini/itvision-avatar.png",
    },
];




// Function to create typing dots
const TypingDots = ({ color }: { color: string }) => {
  return (
    <span className="typing-dots">
      <span className="dot" style={{ backgroundColor: color }}></span>
      <span className="dot" style={{ backgroundColor: color }}></span>
      <span className="dot" style={{ backgroundColor: color }}></span>
    </span>
  );
};

export default function FAQ() {
  const [displayedFAQs, setDisplayedFAQs] = useState<{ type: 'question' | 'answer'; text: JSX.Element | string; clientImage?: string; assistantImage?: string; }[]>([]);
  useEffect(() => {
    const ctx = gsap.context(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: "#faq",
                start: "top center",
            },
        });

        faqs.forEach((faq) => {
            tl.to({}, { duration: 1, onComplete: () => {
                setDisplayedFAQs((prev) => [
                    ...prev,
                    { type: 'question', text: <TypingDots color="#0078ff" />, clientImage: faq.clientImage, assistantImage: faq.assistantImage },
                ]);
            }})
            .to({}, { duration: 1, onComplete: () => {
                setDisplayedFAQs((prev) => {
                    const lastQuestion = prev[prev.length - 1]; // Get last question
                    return [
                        ...prev.slice(0, -1),
                        { type: 'question', text: faq.question, clientImage: lastQuestion.clientImage, assistantImage: lastQuestion.assistantImage },
                    ];
                });
            }})
            .to({}, { duration: 1, onComplete: () => {
                setDisplayedFAQs((prev) => [
                    ...prev,
                    { type: 'answer', text: <TypingDots color="white" />, clientImage: prev[prev.length - 1]?.clientImage, assistantImage: faq.assistantImage }, // Use faq.assistantImage
                ]);
            }})
            .to({}, { duration: 1, onComplete: () => {
                setDisplayedFAQs((prev) => {
                    const lastAnswer = prev[prev.length - 1]; // Get last answer
                    return [
                        ...prev.slice(0, -1),
                        { type: 'answer', text: faq.answer, clientImage: lastAnswer.clientImage, assistantImage: lastAnswer.assistantImage }, // Use lastAnswer.assistantImage
                    ];
                });
            }});
        });
    });

    return () => ctx.revert();
}, []);

  return (
    <Container
      id="faq"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        maxWidth: '600px',
        marginTop: 2,
        minHeight: '900px',
        zIndex:50,
      }}
    >

   
      {displayedFAQs.map((faq, index) => (
        <React.Fragment key={index}>
          {/* Client's Message */}
          {faq.type === 'question' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginBottom: 2,
                animation: 'fadeIn 0.5s',
                
              }}
            >
              <Avatar
                src={faq.clientImage} // Use the client image
                alt="Client Avatar"
                sx={{ bgcolor: 'primary.main', marginRight: 1 }}
              >
                {!faq.clientImage ? 'C' : ''} {/* Use initials if image is unavailable */}
              </Avatar>
              <Box
                sx={{
                  bgcolor: '#f1f1f1',
                  padding: '10px',
                  borderRadius: '15px',
                  maxWidth: '70%',
                  animation: 'slideInLeft 0.5s',
                  boxShadow: 2,
                  color:'#36454F',
                  transition: 'all 0.2s ease',
                }}
              >
                <Typography sx={{animation: 'slideInLeft 0.5s', transition: 'all 0.2s ease',}}>{faq.text}</Typography>
              </Box>
            </Box>
          )}

          {/* Your Answer */}
          {faq.type === 'answer' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginBottom: 2,
                animation: 'fadeIn 0.5s',
              }}
            >
              <Box
                sx={{
                  bgcolor: 'primary.main',
                  padding: '10px',
                  color: 'white',
                  borderRadius: '15px',
                  maxWidth: '70%',
                  animation: 'slideInRight 0.5s',
                  boxShadow: 2,
                  transition: 'all 0.2s ease',
                }}
              >
               <Typography sx={{animation: 'slideInRight 0.5s', transition: 'all 0.2s ease',}}>{faq.text}</Typography>
              </Box>
              <Avatar
                src={faq.assistantImage} // Use the assistant image
                alt="Assistant Avatar"
                sx={{ bgcolor: 'white', marginLeft: 1 }}
              >
                {!faq.assistantImage ? 'A' : ''} {/* Use initials if image is unavailable */}
              </Avatar>
            </Box>
          )}
        </React.Fragment>
      ))}
    </Container> 
  );
}
